import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(): void {
    if (typeof localStorage !== 'undefined')
      localStorage.clear();
  }

  /**
   * Save a particular item to the storage service
   * @param key key of the item to be saved
   * @param value value of the item
   */
  public save(key: string, value: any): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key);
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Remove a particular item from the storage service
   * @param key key of the item to be removed
   */
  public delete(key: string): void {
    if (typeof localStorage !== 'undefined')
      localStorage.removeItem(key);
  }

  /**
   * Update an existing item in the storage service
   * @param key key of the item to be updated
   * @param value value of the item
   */
  public update(key: string, value: any): void {
    if (typeof localStorage !== 'undefined') {
      let old_value = this.get(key);
      if (old_value) {
        old_value = { ...old_value, ...value };
        this.save(key, old_value);
      } else
        this.save(key, value);
    }
  }

  /**
   * Get the item from the storage service
   * @param key key of the item to be retrieved
   * @returns the value of the item
   */
  public get(key: string, parse: boolean = true): any {
    if (typeof localStorage !== 'undefined') {
      const value = localStorage.getItem(key);
      if (value && parse)
        return JSON.parse(value);
      
      if (value)
        return value;

      return null;
    } else 
      return null;
  }
}
