import {
  provideTransloco,
  TranslocoModule,
  TranslocoService
} from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from 'src/environments/environment';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
      storageKey: 'lang'
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'it'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
  ],
})
export class TranslocoRootModule {}
