<div class="app-wrapper h-screen">
  <p-toast />
  <!-- topbar -->
  <app-topbar></app-topbar>
  <!-- topnav-->

  <div class="content-page min-h-screen">
    <div class="content">
      <!-- content -->
      <div class="px-4 py-2 md:px-6 lg:px-8">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>