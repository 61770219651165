import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { HorizontalComponent } from './layouts/horizontal/horizontal.component';
import { AuthGuardService } from './_utils/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', component: HorizontalComponent, canActivate: mapToCanActivate([AuthGuardService]), loadChildren: () => import('../app/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'user', component: HorizontalComponent, canActivate: mapToCanActivate([AuthGuardService]), loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
  { path: 'auth', loadChildren: () => import('../app/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'login', redirectTo: '/auth/login' },
  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
