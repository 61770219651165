import { Component, OnInit } from '@angular/core';
import { APP_VERSION } from 'src/app/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear!: number;
  appVersion: string;

  constructor() {
    this.appVersion = 'v' + APP_VERSION;
  }

  ngOnInit(): void {
    this.currentYear = Date.now();
  }
}
